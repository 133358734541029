import {
  Button,
  Container,
  Flex,
  IconButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useIsMobile } from "../../../../hooks/useIsMobile";

import { CardList, CardListItem, Heading, Password, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import { useFormik } from "formik";
import { useChangePassword } from "../../../../hooks/useUsers";
import { AddressModal } from "./AddressModal";
import { ProfileModal } from "./ProfileModal";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
    ),
  confirmPassword: Yup.string()
    .required("Please enter the password again")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
const Profile = (props) => {
  const profileModal = useDisclosure();
  const addressModal = useDisclosure();
  const toast = useToast();
  const { data: me, refetch } = useCurrentUser();
  const isMobile = useIsMobile();

  const changePassword = useChangePassword();

  const onSubmit = async ({ password }, { resetForm }) => {
    try {
      await changePassword.mutateAsync({ password });
      resetForm();
      toast({
        status: "success",
        description: `You successfully changed your password.`,
      });
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: e.data.error,
      });
    }
  };

  const { errors, touched, getFieldProps, handleSubmit, isValid } = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit,
    validateOnMount: true,
    validateOnChange: true,
  });

  return (
    <>
      <Container minW="full" my="4">
        {!isMobile && (
          <Heading
            textStyle="h1"
            as="h1"
            my="10"
            fontWeight="bold"
            fontSize="30px"
          >
            Profile
          </Heading>
        )}
        <Flex align="center">
          <CardList w="full">
            <CardListItem
              action={
                <IconButton
                  onClick={profileModal.onOpen}
                  icon={<Icon.Edit size="1.5em" />}
                  color="equidefi.blue"
                  alignItems="flex-start"
                  pt="4"
                />
              }
            >
              <Heading mb="1" fontWeight="bold">
                Profile
              </Heading>
              <Text m="0">
                {me?.first_name && me?.last_name
                  ? `${me?.first_name} ${me?.middle_name ?? ""} ${
                      me?.last_name
                    }`
                  : "Full Name"}
              </Text>
              <Text m="0">{me?.email}</Text>
              <Text m="0">{me?.phone}</Text>
            </CardListItem>
            <CardListItem
              action={
                <IconButton
                  onClick={addressModal.onOpen}
                  icon={<Icon.Edit size="1.5em" />}
                  color="equidefi.blue"
                  alignItems="flex-start"
                  pt="4"
                />
              }
            >
              <Heading mb="1" fontWeight="bold">
                Address
              </Heading>
              <Text m="0">{me?.address_street_1 || ""}</Text>
              <Text m="0">{me?.address_street_2 || ""}</Text>
              <Text m="0">{me?.address_city || ""}</Text>
              <Text mb="0">{`${me?.address_state || ""} ${
                me?.address_postal_code || ""
              }`}</Text>
              <Text m="0">{`${me?.address_country || ""}`}</Text>
            </CardListItem>
            <CardListItem>
              <Heading mb="2" fontWeight="bold">
                Update Password
              </Heading>
              <form noValidate onSubmit={handleSubmit}>
                <Text mb="5" textStyle="body2">
                  Enter a secure password for your account
                </Text>
                <Password
                  placeholder="Password"
                  error={errors.password}
                  isInvalid={errors.password && touched.password}
                  h="12"
                  {...getFieldProps("password")}
                />

                <Password
                  placeholder="Confirm Password"
                  error={errors.confirmPassword}
                  isInvalid={errors.confirmPassword && touched.confirmPassword}
                  h="12"
                  {...getFieldProps("confirmPassword")}
                />

                <Button
                  isLoading={changePassword.isLoading}
                  isDisabled={!isValid}
                  type="submit"
                  h="12"
                  w="full"
                >
                  Reset Password
                </Button>
              </form>
            </CardListItem>
          </CardList>
        </Flex>
      </Container>
      <ProfileModal modal={profileModal} onSave={refetch} me={me} />
      <AddressModal modal={addressModal} onSave={refetch} me={me} />
    </>
  );
};

export default Profile;
